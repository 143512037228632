/* Add to your global stylesheet */
body {
  font-family: 'Roboto', sans-serif; /* Ensure a modern, readable font is used */
  margin: 0;
  padding: 0;
  background-color: #f4f4f4; /* A light grey background */
  color: #333; /* Dark grey text for better readability */
}




.start-message {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.start-message.active {
  opacity: 1;
}

.message-container {
  transform: translateY(-50px);
  opacity: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.message-container.active {
  transform: translateY(0);
  opacity: 1;
}


.end-message {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.end-message.active {
  opacity: 1;
}

.message-container {
  transform: translateY(-50px);
  opacity: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.message-container.active {
  transform: translateY(0);
  opacity: 1;
}
